<template>
  <div class="d-flex justify-content-center">
    <b-card class="content-card">
      <p>
        To be added ...
      </p>

      <b-form-textarea
        v-model="message"
        class="mb-1"
        placeholder="Type in your message..."
        rows="5"
        disabled
      />

      <b-button
        variant="primary"
        disabled
      >Submit</b-button>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BFormTextarea,
  BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BFormTextarea,
    BButton,
  },
  data() {
    return {
      message: '',
    }
  },
}
</script>

<style scoped>
.content-card {
  width: 75%
}

@media screen and (max-width: 1024px) {
  .content-card {
    width: 100%;
  }
}
</style>
